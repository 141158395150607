import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Form, Col, Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
//import logo from '../../assets/img/brand/logo.svg'
import logo from '../../assets/img/brand/logo-small.png'
//import sygnet from '../../assets/img/brand/sygnet.svg'
import sygnet from '../../assets/img/brand/logo.png'
import Select from 'react-select';
import axios from 'axios';

import IdleTimer from 'react-idle-timer';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      redirectToReferrer: false,
    };
    this.logout = this.logout.bind(this);

    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.logout = this.logout.bind(this)
  }
  
  handleOnAction (event) {
    // console.log('user did something', event)
  }

  handleOnActive (event) {
    // console.log('user is active', event)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle (event) {
    // console.log('user is idle', event)
    this.logout()
  }

  logout(){
    console.log(`trying to logout`)
    sessionStorage.setItem("userDataadmin",'');
    localStorage.setItem("userData", '');
    sessionStorage.clear();
    localStorage.clear();

    this.setState({redirectToReferrer: true});
  }

  componentWillMount() {

  } 

  render() {
    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/login'}/>)
    }
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (

      <>
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        timeout={1000 * 60 * 30}
        onActive={this.handleOnActive}
        onIdle={this.handleOnIdle}
        onAction={this.handleOnAction}
        debounce={250}
      />

      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 245, height: 56, alt: 'PokerPeek Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'PokerPeek Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="d-md-down-none" navbar>     
        </Nav>                  
        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={'assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              {/*
              <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>
              <DropdownItem divider />
              <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>
            */ }
              <DropdownItem onClick={this.logout}><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/*
        <AppAsideToggler className="d-md-down-none" />
        <AppAsideToggler className="d-lg-none" mobile />
      */}
      </React.Fragment>
      </>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
