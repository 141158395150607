import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}



const Dashboard = Loadable({
  loader: () => import('./views/Dashboard/Dashboard'),
  loading: Loading,
});



const Members = Loadable({
  loader: () => import('./views/Poker/Members'),
  loading: Loading,
});

const Players = Loadable({
  loader: () => import('./views/Poker/Players'),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import('./views/Poker/Tables'),
  loading: Loading,
});

const Tournaments = Loadable({
  loader: () => import('./views/Poker/Tournaments'),
  loading: Loading,
});

const Clubs = Loadable({
  loader: () => import('./views/Poker/Clubs'),
  loading: Loading,
});





// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/members', exact: true, name: 'Members', component: Members },
  { path: '/tables', exact: true, name: 'Tables', component: Tables },
  { path: '/players', exact: true, name: 'Players', component: Players },
  { path: '/tournaments', exact: true, name: 'Tournaments', component: Tournaments },
  { path: '/clubs', exact: true, name: 'Clubs', component: Clubs },
  

 
];

export default routes;
