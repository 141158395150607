const config = {
    //API_URL: 'http://localhost:3010/',
    API_URL: 'https://api.pokerpeek.com/',
    //SOCKETIO_URL:'https://localhost:4003/',    
    Head_config: {
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          },
    API_GOOGLE_KEY : 'AIzaSyCmVpzlWlcSxxf2pEW_eUj7OpkQf8yO9M4'
    //SOCKETIO_URL : 'https://table.pokerpeek.com:13001' 
};
export default config;