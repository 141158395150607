import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../../services/PostData';
import { Button, Card, CardBody, CardFooter, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

class Register extends Component {

  constructor(props){
    super(props);
   
    this.state = {
     username: '',
     password: '',
     email: '',
     profile: '',
     usercode: '',
     redirectToReferrer: false
    };

    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);

  }
 

  signup() {
    if(this.state.username && this.state.password && this.state.email && this.state.usercode){
      
      if(this.state.usercode==='testalin'){
          PostData('register',this.state).then((result) => {
            console.log(result);
            let responseJson = result;
            if(responseJson.userData){         
              sessionStorage.setItem('userDataadmin',JSON.stringify(responseJson));
              this.setState({redirectToReferrer: true});
            }
            
           });
      } else {
        console.log('wrong invitecode');
      }
    }
  }

 onChange(e){
   this.setState({[e.target.name]:e.target.value});
  }



  render() {

    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/login'}/>)
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
        <Row className="justify-content-center logo">
            <Col md="8" sm="10" className="text-center">
              <img src={'assets/img/logo.png'} className="mb-4 mx-auto"/>
              <h1 className="mb-1">Eyefit.io</h1>
              <h2 className="mb-4">Analytic for Fashion & Retail Industry</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <Form>
                    <h1>Register</h1>
                    <p className="text-muted">Create your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Username" autoComplete="username" name="username" onChange={this.onChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Email" autoComplete="email" name="email" onChange={this.onChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Password" autoComplete="new-password" name="password" onChange={this.onChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Repeat password" autoComplete="new-password"  name="repassword" onChange={this.onChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-arrow-right-circle"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Invite Code" autoComplete="usercode" name="usercode" onChange={this.onChange} />
                    </InputGroup>
                    <Button color="success" block onClick={this.signup} >Create Account</Button>
                  </Form>
                </CardBody>
                {/*<CardFooter className="p-4">
                  <Row>
                    <Col xs="12" sm="6">
                      <Button className="btn-facebook" block><span>facebook</span></Button>
                    </Col>
                    <Col xs="12" sm="6">
                      <Button className="btn-twitter" block><span>twitter</span></Button>
                    </Col>
                  </Row>
                </CardFooter>*/}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
