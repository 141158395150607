export default {
    items: [
    /*
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        badge: {
          variant: 'info',
          text: 'NEW',
        },
      },
      */
      {
        name: 'Clubs',
        url: '/clubs',
        icon: 'icon-puzzle',
      },                    
      {
        name: 'Tournaments',
        url: '/tournaments',
        icon: 'icon-puzzle',
      },
      /*
      {
        name: 'Tables',
        url: '/tables',
        icon: 'icon-puzzle',
      },
      {
        name: 'Players',
        url: '/players',
        icon: 'icon-puzzle',
      }, 
      */             
      {
        name: 'Members',
        url: '/members',
        icon: 'icon-puzzle',
    },
    {
      name: 'Users',
      url: '/players',
      icon: 'icon-puzzle',
    }
    ] 
};
