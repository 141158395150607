import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {PostData} from '../../../services/PostData';
import axios from 'axios';
import configuration from '../../../config';
const API_URL = configuration.API_URL;
const QUERYCONFIG = configuration.Head_config;

class Login extends Component {

  constructor(){
    super();
   
    this.state = {
     email: '',
     password: '',
     redirectToReferrer: false
    };

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);         

  }

    register = () => {
        this.props.history.push("/register");
    }

  login() {
    if(this.state.email && this.state.password){

      if ((this.state.email=='admin')&&(this.state.password=='c4rp0n378'))
      {
          var responseJson = {
                          "id":1,
                          "email":this.state.email,
                          "tokken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInVzZXJFbWFpbCI6Im1hbm9hbGluODBAZ21haWwuY29tIiwidXNlclByb2ZpbGUiOjIsImlhdCI6MTU4NDYyMTEyNiwiZXhwIjoxNTg2MjExMzYzfQ.4GLSwX4OM4q-ghG_AeWssqUT-z_zd7sUKvu2tWEHWXg"
                         }
        sessionStorage.setItem('userDataadmin',JSON.stringify(responseJson)); 
         this.setState({redirectToReferrer:true});          
      }  

/*
      PostData('login',this.state).then((result) => {
       let responseJson = result;
       if(responseJson.userData)
       {         
         sessionStorage.setItem('userDataadmin',JSON.stringify(responseJson));                          
       }
       
      });
*/      
    }
    
   }

  onChange(e){
    this.setState({[e.target.name]:e.target.value});
   }

  render() {

    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/clubs'}/>)
    }
   
    if(sessionStorage.getItem('userDataadmin')){
      var datalogin = JSON.parse(sessionStorage.getItem("userDataadmin"));
      var email = datalogin.email;  
      if (email==='admin'){
        return (<Redirect to={'/clubs'}/>)
      }
      
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
        <Row className="justify-content-center logo">
            <Col md="8" sm="10" className="text-center">
              <img src={'assets/img/logo1.png'} className="mb-4 mx-auto"/>                            
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="4" sm="10">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" name="email" placeholder="Username" autoComplete="email" onChange={this.onChange}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" name="password" placeholder="Password" autoComplete="current-password" onChange={this.onChange}/>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" onClick={this.login}>Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          {/* <Button color="link" className="px-0">Forgot password?</Button> */}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
  {/* 
                <Card className="text-white bg-primary py-5">
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                       <p className="sign_up_text">Access for professionals of fashion & retail industry</p>
                       <div className="alpha_notice">An invite code will be required<br /></div><br />
                      <Button color="primary" className="mt-3" active onClick={this.register}>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card>
  */}                
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
